import { Input, typo, Button } from '@cycle-app/ui';
import { InfoIconOutline } from '@cycle-app/ui/icons';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { PortalModal } from '../PortalModal';

export const StyledInfoIcon = styled(InfoIconOutline)`
  color: ${({ theme }) => theme.colors.text.disabled}
`;

export const StyledSubscriptionContent = styled(motion.div)`
  overflow-y: clip;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${p => p.theme.colors.text.secondary};
`;

export const StyledWebhookModalTrigger = styled.span`
  margin: 0;
  cursor: pointer;
  font-size: inherit;
  color: ${p => p.theme.colors.text.primary};
`;

export const StyledCreateButton = styled(Button)`
  margin-top: 8px;
  gap: 4px;
  font-weight: normal;
`;

/* webhook modal */

export const StyledModal = styled(PortalModal)`
  width: 400px;
`;

export const StyledModalTitle = styled.h2`
  ${typo.headerLight}
`;

export const StyledP = styled.p`
  margin: 16px 0;
  color: ${p => p.theme.colors.text.primary};
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledInput = styled(Input)`
  margin-top: 8px;
`;
