import { Skeleton, CompanyLogo, CustomerAvatar } from '@cycle-app/ui';
import { CloseIcon, SummaryIcon, ExpandIcon } from '@cycle-app/ui/icons';
import { AnimatePresence } from 'framer-motion';

import { CustomerLinkedProfiles } from 'src/components/CustomerProfile/CustomerLinkedProfiles';
import { CustomerActionsMenu } from 'src/components/CustomersList/CustomerActionsMenu';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { ExportQuotesFromCustomer } from 'src/components/ExportQuotesModal';
import { TooltipClickToCopy } from 'src/components/TooltipClickToCopy';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useCustomer } from 'src/hooks/api/queries/customers/useCustomer';
import { openCompanyModal } from 'src/hooks/modals/useCompanyModal';
import { useIsSmallScreen } from 'src/reactives';

import { DocsCountCustomer } from './DocsCountCustomer';
import {
  ProfileContainer, ProfileMain, ProfileMainContent, ProfileTopBar, ProfileTopBarContent,
  ProfileHeader, ProfileActions, ExpandButton, CloseButton,
  ImageContainer, StyledCustomerAvatar, ImageSkeleton, Skeletons,
  ProfileInfos, Summary, SummaryTitle, InfosTitle, InfosSubtitle, TextButton, Left,
} from './Profile.styles';
import { CustomerSummaryModal } from './SummaryModal';
import { TabsCustomer } from './TabsCustomer';
import { useExpandTabsSection } from './useExpandTabsSection';

type Props = {
  customerId: string;
  onClose?: VoidFunction;
  onExpand?: VoidFunction;
};

export const CustomerProfile = ({
  customerId, onClose, onExpand,
}: Props) => {
  const {
    customer, loading,
  } = useCustomer(customerId);
  const company = customer?.company;
  const isSmallScreen = useIsSmallScreen();

  const {
    profileRef, tabPanelsRef, tabsExpanded, handlePan,
  } = useExpandTabsSection();

  const actions = (
    <ProfileActions>
      {onClose && (
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      )}
      {onExpand && (
        <ExpandButton
          tooltip="Open full page"
          onClick={onExpand}
        >
          <ExpandIcon size={18} />
        </ExpandButton>
      )}
    </ProfileActions>
  );

  const menu = customer && (
    <CustomerActionsMenu customer={customer} />
  );

  const subtitle = customer && (
    <>
      {customer.email && (
        <TooltipClickToCopy
          title={customer.email}
          value={customer.email}
        >
          {props => (
            <TextButton {...props}>
              {customer.email}
            </TextButton>
          )}
        </TooltipClickToCopy>
      )}

      {company
        ? (
          <TextButton
            tooltip="Open company"
            tooltipPlacement="top"
            onClick={() => openCompanyModal(company.id)}
          >
            <CompanyLogo company={company} size="S" />
            {company.name}
          </TextButton>
        )
        : (
          <div>No company</div>
        )}

      {!!customer.profiles?.length && (!isSmallScreen || !tabsExpanded) && (
        <CustomerLinkedProfiles customer={customer} />
      )}
    </>
  );

  return (
    <ErrorBoundary>
      <BoardConfigContextProvider>
        <ProfileContainer
          ref={profileRef}
          onPan={(e, info) => {
            handlePan(e.target, info.delta.y);
          }}
        >
          <ProfileMain
            animate={{
              height: tabsExpanded ? 28 : 'auto',
            }}
          >
            <ProfileMainContent>
              <ProfileHeader>
                {actions}
                {menu}
              </ProfileHeader>

              <ImageContainer>
                {loading ? <ImageSkeleton /> : (customer && (
                  <StyledCustomerAvatar
                    customer={customer}
                    companyTitle={company?.name}
                    companyTooltip={company ? 'Open company' : undefined}
                    onCompanyClick={() => {
                      if (company) openCompanyModal(company.id);
                    }}
                  />
                ))}
              </ImageContainer>

              <ProfileInfos>
                {loading ? (
                  <Skeletons>
                    <Skeleton height={16} width={150} />
                    <Skeleton height={16} width={250} />
                  </Skeletons>
                ) : (customer && (
                  <>
                    <InfosTitle>{customer.name}</InfosTitle>
                    <InfosSubtitle>
                      <Left>{subtitle}</Left>
                    </InfosSubtitle>
                  </>
                ))}
              </ProfileInfos>

              <Summary>
                <SummaryTitle>
                  <SummaryIcon />
                  Summary
                </SummaryTitle>
                <DocsCountCustomer customerId={customerId} />
              </Summary>
            </ProfileMainContent>
          </ProfileMain>

          <TabsCustomer customerId={customerId} tabPanelsRef={tabPanelsRef} />

          <ExportQuotesFromCustomer customerId={customerId} />
          <CustomerSummaryModal customerId={customerId} />

          <AnimatePresence>
            {tabsExpanded && (
              <ProfileTopBar
                key="topBar"
                initial={{ top: -44 }}
                animate={{ top: 0 }}
                exit={{ opacity: -44 }}
              >
                {actions}

                <ProfileTopBarContent>
                  {loading ? (
                    <Skeleton height={16} width={200} />
                  ) : (
                    <>
                      {customer && (
                        <CustomerAvatar
                          size="S"
                          customer={customer}
                          hideCompany
                        />
                      )}
                      <InfosTitle>{customer?.name}</InfosTitle>
                      <InfosSubtitle>
                        <Left>
                          {subtitle}
                        </Left>
                      </InfosSubtitle>
                    </>
                  )}
                </ProfileTopBarContent>

                {menu}
              </ProfileTopBar>
            )}
          </AnimatePresence>
        </ProfileContainer>
      </BoardConfigContextProvider>
    </ErrorBoundary>
  );
};
