/* eslint-disable no-nested-ternary */
import { Badge, Spinner } from '@cycle-app/ui';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import { RefObject } from 'react';

import { useCustomerDocsCount } from 'src/hooks/api/queries/customers/useCustomerDocsCount';
import {
  setCustomerTabId,
  setCustomerStatusCategory,
  useCustomerTabId,
  useCompanyStatusCategory,
} from 'src/reactives/customerCompanyProfile.reactive';
import { useInsightParentDocTypes } from 'src/reactives/docTypes.reactive';

import { FeaturesCounters } from './FeaturesCounters';
import { FeaturesListCustomer } from './FeaturesListCustomer';
import {
  Tabs, TabsScrollable, TabList, Tab, TabPanels, TabPanel, FeaturesHeader,
} from './Profile.styles';
import { QuotesListCustomer } from './QuotesListCustomer';
import { StatusCategorySelect } from './StatusCategorySelect';

type Props = {
  customerId: string;
  tabPanelsRef?: RefObject<HTMLDivElement>;
};

export const TabsCustomer = ({
  customerId, tabPanelsRef,
}: Props) => {
  const {
    docsCount, isLoaded: isCountLoaded,
  } = useCustomerDocsCount(customerId);

  const tabId = useCustomerTabId();
  const statusCategory = useCompanyStatusCategory();

  const docTypes = useInsightParentDocTypes();
  if (!docTypes.length) return null;

  const quotesCount = sumBy(docsCount, 'totalInsights');
  const sortedDocTypes = sortBy(docTypes, 'name');

  const tabIds = ['quotes', ...sortedDocTypes.map(docType => docType.id)];
  const tabIndex = tabIds.indexOf(tabId);

  return (
    <Tabs
      index={tabIndex < 0 ? 0 : tabIndex}
      onChange={index => setCustomerTabId(tabIds[index] ?? 'quotes')}
    >
      {({ selectedIndex }) => (
        <>
          <TabsScrollable>
            <TabList>
              <Tab
                $selected={selectedIndex === 0}
              >
                Quotes
                {!docsCount ? <Spinner /> : <Badge>{quotesCount}</Badge>}
              </Tab>

              {sortedDocTypes.map((docType, index) => {
                const count = docsCount?.find(c => c.doctype?.id === docType.id)?.total ?? 0;
                return (
                  <Tab
                    key={docType.id}
                    $selected={selectedIndex === index + 1}
                  >
                    {docType.name}
                    {!docsCount ? <Spinner /> : <Badge>{count}</Badge>}
                  </Tab>
                );
              })}
            </TabList>
          </TabsScrollable>

          <TabPanels ref={tabPanelsRef}>
            <TabPanel>
              {selectedIndex === 0 && <QuotesListCustomer customerId={customerId} />}
            </TabPanel>

            {sortedDocTypes.map((docType, index) => {
              const count = docsCount?.find(c => c.doctype?.id === docType.id);
              return (
                <TabPanel key={docType.id}>
                  {selectedIndex === index + 1 && (
                    <>
                      <FeaturesHeader>
                        <StatusCategorySelect
                          value={statusCategory}
                          onChange={setCustomerStatusCategory}
                        />
                        {isCountLoaded && <FeaturesCounters count={count} />}
                      </FeaturesHeader>

                      <FeaturesListCustomer
                        customerId={customerId}
                        doctypeId={docType.id}
                      />
                    </>
                  )}
                </TabPanel>
              );
            })}
          </TabPanels>
        </>
      )}
    </Tabs>
  );
};
