import { ApolloError } from '@apollo/client';
import { IntegrationFullFragment, IntegrationType } from '@cycle-app/graphql-codegen';
import { ToasterAction } from '@cycle-app/ui';
import { normalizeString } from '@cycle-app/utilities';
import { useCallback } from 'react';

import { Events } from 'src/constants/analytics.constants';
import { integrationsDataMap } from 'src/constants/integrations.constants';
import { useCreateIntegration } from 'src/hooks/api/mutations/integrations/useCreateIntegration';
import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import { useProductAddOn } from 'src/hooks/useProductAddOns';
import { openLinearOnboarding } from 'src/reactives/linearImport.reactive';
import { openSubdomainModal } from 'src/reactives/subdomain.reactive';
import { FrontEndIntegration } from 'src/types/integrations.types';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { logError } from 'src/utils/errors.utils';
import { addErrorToaster } from 'src/utils/errorToasters.utils';
import { integrationNameTitles } from 'src/utils/integrations.utils';
import { addToaster } from 'src/utils/toasters.utils';

import { getOnboardingSlackStep, startOnboardingSlack, useGetIntegrationPermission } from '../reactives';
import { setIntegrationSync } from '../reactives/integrationSync.reactive';
import { setIntegrationSyncModal } from '../reactives/integrationSyncModal.reactive';
import { setLimitationsModal } from '../reactives/limitationsModal.reactive';
import { setMeetingsModalInstalled } from '../reactives/meetingsModalInstalled.reactive';
import { useProductBase } from './api/useProduct';
import { useIsRoadmapsEnabled } from './roadmap';
import { useFeatureFlag } from './useFeatureFlag';

import type { AddOn } from 'src/types/addOn.types';
import type { Integration } from 'src/types/integrations.types';

type InstallStatus = 'success' | 'error';

type IntegrationCallback = (status: InstallStatus, error?: ApolloError | Error) => void;

declare global {

  interface Window {
    onIntegrationEnd: Record<string, IntegrationCallback>;
    subdomain?: string;
  }
}

const POPUP_WIDTH = 600;
const POPUP_HEIGHT = 600;
const INTEGRATIONS_AS_ADD_ONS: Partial<Record<Integration, AddOn['name']>> = {
  [FrontEndIntegration.ATTIO]: 'attio',
  [FrontEndIntegration.CONFLUENCE]: 'confluence',
  [FrontEndIntegration.FRESHDESK]: 'fresh-desk',
  [FrontEndIntegration.GAINSIGHT]: 'grain-sight',
  [FrontEndIntegration.JIRA]: 'jira',
  [FrontEndIntegration.PLANHAT]: 'plan-hat',
  [FrontEndIntegration.SNOWFLAKE]: 'snow-flake',
  [FrontEndIntegration.VITALLY]: 'vitally',
  [IntegrationType.Salesforce]: 'SALESFORCE',
  // [IntegrationType.Zendesk]: 'ZENDESK',
  [IntegrationType.Meeting]: 'MEETINGS',
};

const popupParams = () => Object.entries({
  scrollbars: 'yes',
  resizable: 'yes',
  status: 'no',
  location: 'no',
  toolbar: 'no',
  menubar: 'no',
  left: window.screenLeft + window.outerWidth / 2 - POPUP_WIDTH / 2,
  top: window.screenTop + window.outerHeight / 2 - POPUP_HEIGHT / 2,
  width: POPUP_WIDTH,
  height: POPUP_HEIGHT,
}).map(entry => entry.join('=')).join(',');

type UseInstallIntegrationReturn = (
  type: Integration,
  integration?: {
    type?: IntegrationFullFragment['type'];
    url?: string;
  },
  onInstallationDone?: VoidFunction
) => Promise<void>;

export const useInstallIntegration = (): UseInstallIntegrationReturn => {
  const { refetch } = useProductIntegrations();
  const { createIntegration } = useCreateIntegration();
  const { canAddIntegration } = useGetIntegrationPermission();
  const product = useProductBase();
  const { isEnabled: isOnboardingSlackEnabled } = useFeatureFlag('slack-onboarding-2-0');
  const { isEnabled: isMeetingIntegrationEnabled } = useProductAddOn('MEETINGS');
  const { isEnabled: isHubSpotEnabled } = useProductAddOn('HUBSPOT');
  const isRoadmapEnabled = useIsRoadmapsEnabled();

  const productId = product?.id;

  return useCallback(async (type, integration, onInstallationDone) => {
    if (!integration?.type && Object.keys(INTEGRATIONS_AS_ADD_ONS).includes(type)) {
      setLimitationsModal({
        action: 'USE_ADD_ON',
        brand: (INTEGRATIONS_AS_ADD_ONS as Record<Integration, AddOn['name']>)[type] ?? null,
      });
    }
    if (!integration?.type) return;
    if (
      integration.type === IntegrationType.Linear &&
      !isRoadmapEnabled
    ) {
      setLimitationsModal({ action: 'ROADMAP_NEEDED_FOR_LINEAR' });
      return;
    }
    if (!productId) return;
    if (
      // First call of install(slack) will start the onboarding.
      integration.type === IntegrationType.Slack &&
      isOnboardingSlackEnabled &&
      // Second call of install(slack) in the Installation Step should install the integration.
      !getOnboardingSlackStep(productId)
    ) {
      startOnboardingSlack(productId);
      return;
    }
    if (integration?.type === IntegrationType.Hubspot && !isHubSpotEnabled) {
      setLimitationsModal({
        action: 'USE_ADD_ON',
        brand: 'HUBSPOT',
      });
      return;
    }
    // Everyone can install the meeting integration.
    if (type !== IntegrationType.Meeting && !canAddIntegration) {
      setLimitationsModal({ action: 'INTEGRATION_ADD' });
      return;
    }

    if (integration.type === IntegrationType.Mail) {
      await createIntegration({
        productId,
        type: IntegrationType.Mail,
      });
      refetch();
      return;
    }

    if (integration.type === IntegrationType.Meeting && !isMeetingIntegrationEnabled) {
      setLimitationsModal({
        action: 'USE_ADD_ON',
        brand: 'MEETINGS',
      });
      refetch();
      return;
    }

    if (!integration?.url) return;

    const title = integrationNameTitles[integration?.type];
    const provider = normalizeString(integration?.type);

    const install = () => {
      const url = (integration.type === IntegrationType.Zendesk || integration.type === IntegrationType.Salesforce) && window.subdomain
        ? integration.url?.replace('d3v-cycle3554', window.subdomain).replace('cycleapp-dev-ed.develop', window.subdomain)
        : integration.url;
      const popup = window.open(url, '_blank', popupParams());

      const callback: IntegrationCallback = (status, error) => {
        if (!integration?.type) return;
        delete window.onIntegrationEnd?.[provider];
        try {
          popup?.close();
        } catch {
        //
        }
        switch (status) {
          case 'success': {
            refetch();
            // right now the slack installation is the only one we cannot track from the backend
            if (integration?.type === 'SLACK') {
              trackAnalytics(Events.IntegrationInstalled, {
                type: integration?.type,
                productId,
              });
            }
            if (integration?.type === IntegrationType.Intercom || type === IntegrationType.Hubspot) {
              setIntegrationSyncModal({ integrationType: integration?.type });
              onInstallationDone?.();
              return;
            }
            if (integration.type === IntegrationType.Linear) {
              openLinearOnboarding({ isSkippable: true });
              onInstallationDone?.();
              return;
            }
            if (integration.type === IntegrationType.Meeting && productId) {
              setMeetingsModalInstalled({ isOpened: true });
              onInstallationDone?.();
              return;
            }
            setIntegrationSync({ [integration.type]: { isSyncing: true } });
            const { learnUrl } = integrationsDataMap[type];
            if (title) addSuccessInstallToaster(title, learnUrl);
            onInstallationDone?.();
            break;
          }
          case 'error':
            addErrorToaster({
              message: `🤭 Oops, something occur during the installation of ${title}, we’re looking into it, retry later`,
            });
            if (error) logError(error);
            break;
          default:
            break;
        }
      };

      window.onIntegrationEnd = {
        ...window.onIntegrationEnd,
        [provider]: callback,
      };
    };

    if (integration.type === IntegrationType.Zendesk) {
      openSubdomainModal(install, integration.type);
      return;
    }

    if (integration.type === IntegrationType.Salesforce) {
      openSubdomainModal(install, integration.type);
      return;
    }

    install();
  }, [
    isRoadmapEnabled, isOnboardingSlackEnabled, isHubSpotEnabled,
    canAddIntegration, productId, isMeetingIntegrationEnabled,
    createIntegration, refetch,
  ]);
};

export const addSuccessInstallToaster = (title: string, learnUrl?: string) => {
  addToaster({
    title: 'Successfully installed',
    message: `🙌 Your ${title} integration is ready`,
    isCompact: false,
    actions: learnUrl ? (
      <ToasterAction onClick={() => window.open(learnUrl, '_blank')}>
        Learn more
      </ToasterAction>
    ) : null,
  });
};
