import { useCallback, useEffect, useRef, useState } from 'react';

// Profile height below which the feature is enabled
const SMALL_HEIGHT = 800;

// Threshold for wheel/pan event, to prevent accidental expansion/collapse
const THRESHOLD = 4;

/** Expand/Collapse tabs section on scroll */
export const useExpandTabsSection = () => {
  const profileRef = useRef<HTMLDivElement>(null);
  const tabPanelsRef = useRef<HTMLDivElement>(null);
  const [tabsExpanded, setTabsExpanded] = useState(false);

  const handlePan = useCallback((target: EventTarget | null, deltaY: number) => {
    const profileElement = profileRef.current;
    const tabPanelsElement = tabPanelsRef.current;

    // Do not handle if elements are not ready
    if (!(target instanceof Element) || !tabPanelsElement || !profileElement) return;

    // Do not expand/collapse if the tabs section is large enough
    if (!tabsExpanded && profileElement.clientHeight > SMALL_HEIGHT) return;

    // Only capture events inside the profile container
    if (!profileElement.contains(target)) return;

    // Expand tabs section if panning up or down in the top section
    if (!tabPanelsElement.contains(target)) {
      if (Math.abs(deltaY) > THRESHOLD) {
        setTabsExpanded(state => !state);
      }
      return;
    }

    // Expand tabs section if panning up in the tabs section
    if (deltaY < THRESHOLD) {
      setTabsExpanded(true);
      return;
    }

    // Collapse tabs section if panning down in the tabs section and it's scrolled to the top
    if (deltaY > -THRESHOLD && tabPanelsElement.scrollTop === 0) {
      setTabsExpanded(false);
    }
  }, [tabsExpanded]);

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      const profileElement = profileRef.current;
      const tabPanelsElement = tabPanelsRef.current;

      // Do not handle if elements are not ready
      if (!(e.target instanceof Element) || !tabPanelsElement || !profileElement) return;

      // Do not expand/collapse if the tabs section is large enough
      if (!tabsExpanded && profileElement.clientHeight > SMALL_HEIGHT) return;

      // Only capture events inside the profile container
      if (!profileElement.contains(e.target)) return;

      // Expand tabs section if scrolling down
      if (e.deltaY > THRESHOLD) {
        setTabsExpanded(true);
        return;
      }

      // Collapse tabs section if scrolling up
      if (e.deltaY < -THRESHOLD) {
        // Do not collapse if scrolling in the tabs section and it's not scrolled to the top
        if (!tabPanelsElement.contains(e.target) || tabPanelsElement.scrollTop === 0) {
          setTabsExpanded(false);
        }
      }
    };
    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, [handlePan]);

  return {
    profileRef,
    tabPanelsRef,
    tabsExpanded,
    handlePan,
  };
};
